<template>
  <div>

    <div class="bg_box">
      <div v-if="!isShowPage">
        <div class="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="personbox" v-if="isShowPage">
            <!-- 我的 -->
        <van-nav-bar
            title="我的"
            safe-area-inset-top
            fixed
            placeholder
            :border="false"
        >
          <template #right>
            <img src="@/assets/images/icon_settings_black.png"  alt="" @click="loginout">
          </template>
        </van-nav-bar>
      <!-- 顶部姓名 -->
      <div class="rel">
        <div class="phonttop">
        </div>
        <!-- 顶部 用户信息-->
        <div class="flex abs topbox">
          <div class="imgbox">
            <template v-if="uerinfo.avatar">
              <img :src="uerinfo.avatar" alt="" />
            </template>
            <template v-else>
              <template v-if="uerinfo.realName">
                <img :src="processEnv" alt="" />
              </template>
              <template v-else>

                <img :src="processEnv" alt=""  v-if="uerinfo.auth == 'UNPOST'" />
                <img
                  :src="processEnv"
                  alt=""
                  v-if="uerinfo.auth == 'POST'"
                />
              </template>
            </template>
          </div>
          <div class="namebox">
            <div>
              <div class="postBox">
                <p v-if="uerinfo.realName">{{ uerinfo.realName }}</p>
                <div v-if="uerinfo.auth == 'UNPOST' || uerinfo.auth == 'FAIL'" data-info="certification" @click="isAutoGraph">
                  <div class="pleaseCertify">
                    <img src="@/assets/images/btn_authentication@2x.png" alt="">
                  </div>
                </div>
                <div v-else-if="uerinfo.auth == 'POST'">
                  <div class="pleaseCertify">
                    <img src="@/assets/images/btn_examine@2x.png" alt="">
                  </div>
                </div>
                <div v-else-if="uerinfo.auth == 'DONE'">
<!--                  <div class="pleaseCertify" v-if="!uerinfo.hasOwnProperty('license')">
                    <router-link :to="{ name: 'license', query:{pricing:true}}">
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                    </router-link>
                  </div>
                  <div class="pleaseCertify" v-else-if="!uerinfo.hasOwnProperty('sign')">
                    <router-link :to="{ name: 'AccountContract' }">
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                    </router-link>
                  </div>-->
<!--                    <div class="pleaseCertify" v-else-if="!uerinfo.hasOwnProperty('faceVerify')">
                      <router-link :to="{ name: 'face' }">
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                      </router-link>
                    </div>-->
                  <div class="pleaseCertify">
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                  </div>
                </div>
              </div>

            </div>
            <p class="phone">{{ uerinfo.phone }}</p>
          </div>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="topcenter">
            <!-- 常用功能盒子 -->
            <div class="box">
                    <h1>常用功能</h1>
                    <div class="flex">
                            <!-- 图标盒子 -->
                         <div class="iconBox" @click="linkFunciton(0)">
                             <div class="imgminbox">
                                    <img src="@/assets/images/icon_person_sell_black@2x.png" alt="">
                             </div>
                             <span>卖料订单</span>
                         </div>
                         <div class="iconBox" @click="linkFunciton(1)">
                             <div class="imgminbox">
                                    <img src="@/assets/images/icon_person_buy_black@2x.png" alt="">
                             </div>
                             <span>买料订单</span>
                         </div>
<!--                         <div class="iconBox" @click="linkFunciton(2)">
                            <div class="imgminbox">
                                    <img src="@/assets/images/icon_person_stock_black@2x.png" alt="">
                            </div>
                            <span>存料订单</span>
                         </div>-->

                      <div class="iconBox" @click="linkFunciton(3)">
                             <div class="imgminbox">
                                    <img src="@/assets/images/icon_person_money_black@2x.png" alt="">
                             </div>
                             <span>押金</span>
                         </div>
                    </div>
<!--                    <div class="flex">
                      <div class="iconBox"  @click="linkFunciton(4)">
                        <div class="imgminbox">
                          <img src="@/assets/images/icon_person_material_black@2x.png" alt="">
                        </div>
                        <span>提料管理</span>
                      </div>
                      <div class="iconBox">
                      </div>
                      <div class="iconBox">
                      </div>
                      <div class="iconBox">
                      </div>
                    </div>-->
            </div>
            <!-- 功能列表 -->
            <div class="box">
              <h1>其他功能</h1>
              <div class="flex">
                <!-- 图标盒子 -->
                <div class="iconBox" @click="link(8)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_remind_black@2x.png" alt="">
                  </div>
                  <span>价格提醒</span>
                </div>
                <div class="iconBox" @click="link(0)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_express_black@2x.png" alt="">
                  </div>
                  <span>快递查询</span>
                </div>
                <div class="iconBox" @click="link(1)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_insured_black@2x.png" alt="">
                  </div>
                  <span>货运保单</span>
                </div>
                <div class="iconBox" @click="link(6)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_business_black@2x.png" alt="">
                  </div>
                  <span>业务说明</span>
                </div>
              </div>
              <div class="flex">
                <div class="iconBox" @click="link(2)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_condition_black@2x.png" alt="">
                  </div>
                  <span>回收标准</span>
                </div>
                <div class="iconBox" @click="link(3)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_bankCard_black@2x.png" alt="">
                  </div>
                  <span>银行账号</span>
                </div>
                <div class="iconBox" @click="link(4)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_wenhao_black@2x.png" alt="">
                  </div>
                  <span>常见问题</span>
                </div>
                <div class="iconBox" @click="link(5)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_online_black@2x.png" alt="">
                  </div>
                  <span>联系客服</span>
                </div>
              </div>
              <div class="flex">
<!--                <div class="iconBox" @click="link(9)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_inviteFriends_yellow@2x.png" alt="">
                  </div>
                  <span>邀请好友</span>
                </div>-->
                <div class="iconBox" @click="link(7)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_about_black@2x.png" alt="">
                  </div>
                  <span>关于我们</span>
                </div>
                <div class="iconBox">
                </div>
                <div class="iconBox">
                </div>
                <div class="iconBox">
                </div>
              </div>
            </div>
      </div>
    </div>

    <van-dialog v-model="cershow">
      <div class="title">提示</div>
      <div class="centerbox_dialog">您的账号还未认证，暂无此项权限</div>
      <div class="gocer flex-around">
        <div @click="cershow = false" class="canclebox">取消</div>
        <router-link :to="{ name: 'certification' }">去认证</router-link>
      </div>
    </van-dialog>

    <van-dialog v-model="ceringModel">
      <div class="title">提示</div>
      <div class="centerbox_dialog">您的认证信息正在审核中，请稍后查看</div>
      <div class="gocer" @click="ceringModel = false">确定</div>
    </van-dialog>

    <van-dialog v-model="disableModel" :show-confirm-button="false" get-container="#app">
      <div class="title">提示</div>
      <div class="centerbox_dialog">
        系统升级，请联系相关业务经理
      </div>
      <div class="gocer" @click="disableModel = false">确定</div>
    </van-dialog>

  </div>
</template>
<script>
import * as JieSureWattingMoney from "@/utils/api";
export default {
  data() {
    return {
      isShowPage: false,
      // 是否认证
      realnameBoolean: false,
      // 认证是否通过(审核中)
      underReview:false,
      ceringModel: false,
      disableModel: false,
      cershow: false,

      myMoney: "0.00",
      moible: "--",
      // 用户信息
      uerinfo: "",
      // 初始化克重
      weight_init: "",
      // 卖料订单待处理的数据
      sellMaterials:'',
      // 买料订单待处理的数据
      buyMaterials:'',
      // 人脸未认证时出现的盒子
      isFaceAuthBox:false,
      // 跳转路由信息
      linkList:['pickUp','claimedit','recovery','bank','commonProblem','kefu','BusinessDescription','aboutList', 'priceAlert', 'inviteFriends'],
      // 常用功能路由信息  卖料订单路径   存料订单路径   押金订单路径
      commonFunctionsList:['sellSettlement','buyOrder', 'keepRecord','pricingDeposit', 'materialOrder'],
      processEnv:require(process.env.VUE_APP_ICON)
    };
    },
async mounted() {
    this.de();
    const than = this;
    JieSureWattingMoney.getuser().then(res => {

      than.uerinfo = res;
      if (res.auth === "DONE") {
        // 已经实名认证
        than.realnameBoolean = true;
      }
      if (res.auth === 'POST') {
        // 认证审核中
        than.underReview = true
      }

      localStorage.setItem("userinfo", JSON.stringify(res));
      // if (than.realnameBoolean) {
      //   // than.getWeight();
      //   // 获取金额
      //   // than.getMoney(); ggg
      // }
/*      if (!res.hasOwnProperty('faceVerify')) {
        // 未人脸认证
        than.isFaceAuthBox = true
      }*/

      this.$nextTick(()=>{
        than.isShowPage = true;
      })
    });
    this.moible = localStorage.getItem("moible");
    // // 发请求,获取待处理订单数量
    // let pending = await JieSureWattingMoney.noProcessing()
    //   this.buyMaterials = pending.buy     // 未处理买料
    //   this.sellMaterials = pending.sell  //未处理卖料, 目前包括存料的
    },
  methods: {
    isAutoGraph(){
      if (!this.uerinfo.forbidden) {
        this.disableModel = true
        return
      }
/*       if (this.underReview && !this.uerinfo.hasOwnProperty('sign')) {
              // 暂未签名
            this.$toast('未按粤恒通开户合同进行签名,请签名!')
             setTimeout(()=>{
                this.$router.push({
                name:'AccountContract',
                query:{pricing:true}
              })
              },1000)
              return false
          }*/
       if (this.uerinfo.auth === "UNPOST") {
            //   并未认证
             this.$Loading.show({text:'未实名认证'})
              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification'
              })
              },1000)
              return false
          }
       if (this.uerinfo.auth === 'FAIL' ) {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{pricing:true}
              })
              },1500)
              return false
          }
    },
    // 常用功能区 按钮事件
    linkFunciton(i){
      if (!this.uerinfo.forbidden) {
        this.disableModel = true
        return
      }
        if (this.uerinfo.auth == 'FAIL' ||  this.uerinfo.auth == 'UNPOST') {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{pricing:true}
              })
              },1500)
              return false
          }
        if (this.underReview) {
                // 正在认证
              this.$toast('资料审核中,此功能暂不可用!')
              return false
          }
      if (!this.realnameBoolean) {
        //   并未认证
        this.$Loading.show({text:'未实名认证'})
        setTimeout(()=>{
          this.$Loading.close()
          this.$router.push({
            name:'certification'
          })
        },1000)
        return false
      }
      if (!this.uerinfo.hasOwnProperty('license')) {
        // 暂未签名
        this.$toast(`未营业执照认证!`)
        setTimeout(()=>{
          this.$router.push({
            name:'license',
            query:{pricing:true}
          })
        },1000)
        return false
      }
      if (!this.uerinfo.hasOwnProperty('sign')) {
        // 暂未签名
        this.$toast(`未按${this.GLOBAL}开户合同进行签名,请签名!`)
        setTimeout(()=>{
          this.$router.push({
            name:'AccountContract',
            query:{pricing:true}
          })
        },1000)
        return false
      }
           //   已认证
     this.$router.push({
        name:this.commonFunctionsList[i]
      })
      },
    // 非常用功能区 按钮事件
    link(i){
      if (!this.uerinfo.forbidden && ![2, 4, 5, 6, 7].includes(i)) {
        this.disableModel = true
        return
      }
      if (![2, 4, 5, 6, 7].includes(i)) {
        if (this.uerinfo.auth == 'FAIL' ||  this.uerinfo.auth == 'UNPOST') {
          //   并未认证
          this.$Loading.show({text:'未实名认证'})

          setTimeout(()=>{
            this.$Loading.close()
            this.$router.push({
              name:'certification',
              // query:{pricing:true}
            })
          },1500)
          return false
        }
        if (this.underReview) {
          // 正在认证
          this.$toast('资料审核中,此功能暂不可用!')
          return false
        }
        if (!this.realnameBoolean) {
          //   并未认证
          this.$Loading.show({text:'未实名认证'})
          setTimeout(()=>{
            this.$Loading.close()
            this.$router.push({
              name:'certification'
            })
          },1500)
          return false
        }
        if (!this.uerinfo.hasOwnProperty('license')) {
          // 暂未营业执照认证
          this.$toast(`未营业执照认证!`)
          setTimeout(()=>{
            this.$router.push({
              name:'license',
              query:{pricing:true}
            })
          },1000)
          return false
        }
        if (!this.uerinfo.hasOwnProperty('sign') ) {
          // 暂未签名
          this.$toast(`未按${this.GLOBAL}开户合同进行签名,请签名!`)
          setTimeout(()=>{
            this.$router.push({
              name:'AccountContract',
              query:{pricing:true}
            })
          },1000)
          return false
        }
      }

           //   已认证
          this.$router.push({
                 name:this.linkList[i]
            })
    },
    // 卖料订单 待处理 数据
    de() {
      const s = 1;
      function dede() {
      }
    },
    loginout() {
      this.$router.push({ name: "login_out" });
    },
    nocer() {
      this.cershow = true;
    },
    cering() {
      this.ceringModel = true;
    },
    },
};
</script>
<style lang="scss" scoped="true">
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        background: #39394d;
        .van-nav-bar__title{
            font-size: 36px;
            color: #f6d0ab;
        }
        .van-icon{
            font-size: 40px;
            color: #1a1a1a;
            right: 20px;
        }
        }
     }
}
.phonttop {
  position: relative;
  height: 360px;
  background: #39394d;
  background-size: 100% 100%;
}
.topcenter {
  position: relative;
  top: -100px;
  border-radius: 32px 32px 0 0;
  box-shadow: inset 0px 1px 0px 0px #FFFFFF;
  background: linear-gradient(180deg, #FFE7D9 4%, #FFFFFF 10%);
  //padding: 0 40px;
    //盒子

  .box{
        width: 100%;
        //border-radius: 8px;
        //background-color: #fff;
        padding: 30px 20px 60px;
        //margin-bottom: 30px;
        //box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.05);
        .flex{
            width: 100%;
            display: flex;
            margin-top: 32px;
            .iconBox{
                font-size: 24px;
                text-align: center;
                 flex: 1;
            }
          span {
            margin-top: 16px;
            color: #333333;
            font-size: 28px;
          }
        }
        h1{
            font-size: 36px;
            font-weight: 600;
            padding: 0 20px;
          color: #333333
        }
  }
}
.box:last-child{
  margin: 0;
}
.vanCell{
    margin-bottom: 40px;
}
.vanCell:last-child{
    margin-bottom: 0;
}
.canclebox {
  color: #c1c1c1;
  &:after {
    content: "";
    width: 2px;
    height: 70px;
    background: #c1c1c1;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    top: 10px;
  }
}
.topbox {
  width: 92%;
}
.arrowbox {
  text-align: right;
  position: relative;
  // right: 0;
  top: 25px;
  flex: 1;
  i {
    // font-size: 48px;
    font-size: 40px;
  }
}
.imgminbox {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}
.gocer {
  font-size: 32px;
  padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  color: #FF2D2E;
  text-align: center;
}
.title {
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
}
.centerbox_dialog {
  padding: 0 10px;
  text-align: center;
  font-size: 28px;
}
::v-deep.van-dialog {
  width: 500px;
}
::v-deep.van-button {
  display: none;
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.personbox {
  z-index: 2;
  position: relative;
  overflow-y: hidden;
}
.abs {
  position: absolute;
  top: 28px;
  left: 34px;
  color: #fff;
  .imgbox {
    border-radius: 50px;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
}
.namebox {
  margin-left: 10px;
  padding-top: 10px;
  font-size: 36px;
  .phone {
    margin-top: 0px;
    font-size: 28px !important;
  }
  .postBox{
    display: flex;
    margin-bottom: 6px;
    .pleaseCertify{
      width: 140px;
    }
    p{
      margin-right: 4px;
    }
  }
}
.loading {
  width: 150px;
  height: 15px;
  margin: 0 auto;
  margin-top: 100px;
}
.loading span {
  display: inline-block;
  width: 15px;
  height: 100%;
  margin-right: 15px;
  border-radius: 50%;
  background: #f83637;
  -webkit-animation: load 1.04s ease infinite;
}
.loading span:last-child {
  margin-right: 0px;
}
.custom-title {
    line-height: inherit;
    font-size:26px;
  }

.search-icon {
    top: 9px;
    margin-right: 8px;
    font-size: 34px;
}
.guidepost-icon{
  width: 40px;
  height: 40px;
  position: relative;
  top: 8px;
  margin-right: 10px;
}
.pleaseCertify{
  width: 150px;
  height: 50px;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
</style>
